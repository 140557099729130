<template>
  <div class="outer-container">
    <div class="grid-container">
      <div class="logo-container" v-for="(key, party, index) in parties" :key="'logo' + index" :style="partyStyle(party)">
        <!-- <img :src="partyLogo(party)" alt=""> -->
        <PartyLogo :party="party" :size="'med'" :circle="true" />
      </div>
      <template v-for="(key, party, index) in parties">
        <div class="shadow-container" :key="'shadow' + index" :class="['S', 'ALT', 'RV', 'SF', 'EL'].indexOf(party)!==-1?'red-block':''" :style="shadowStyle(party)"></div>
      </template>
    </div>

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.outer-container {
  background: white;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.grid-container {
  position: relative;
  height: 70%;
  width: 70%;
  .shadow-container {
    position: absolute;
    transition: 3s;
    height: 40%;
    width: 40%;
    border-radius: 50%;
    opacity: 0.5;
    background: radial-gradient(
      circle,
      lighten($color-blue, 10),
      lighten($color-blue, 29),
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    );
    &.red-block {
      background: radial-gradient(
        circle,
        lighten($color-red, 10),
        lighten($color-red, 29),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      );
    }
  }
  .logo-container {
    position: absolute;
    transition: 3s;
    height: 8%;
    width: 8%;
    transform: translate(-4%, -4%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    &:hover {
      z-index: 999;
    }
  }
}
.periods-container {
  margin-top: 10%;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>

<script>
import parties from "@/assets/parties/parties.json";
import PartyLogo from "@/components/PartyLogo";
export default {
  props: {
    sectionData: {},
    fixedPeriod: {
      default: null
    }
  },
  components: {
    PartyLogo
  },
  data() {
    return {
      parties,
      globalPeriod: "allPeriods"
    };
  },
  methods: {
    printPartyName: function(party) {
      return this.parties[party].name;
    },
    partyLogo: function(party) {
      return require(`@/assets/parties/logos/new/${party}_med.png`);
    },
    setSelectedPeriod: function(event) {
      this.globalPeriod = event;
    },
    partyStyle: function(party) {
      if (this.periodParties.map(x => x.partyIDS).indexOf(party) !== -1) {
        let x = this.periodParties.filter(x => x.partyIDS === party)[0].x - 4;
        let y = this.periodParties.filter(x => x.partyIDS === party)[0].y - 4;
        return `top: ${y}%; left: ${x}%`;
      } else {
        return "top: -20%; left: -20%; opacity: 0.1; cursor: initial";
      }
    },
    shadowStyle: function(party) {
      if (this.periodParties.map(x => x.partyIDS).indexOf(party) !== -1) {
        let x = this.periodParties.filter(x => x.partyIDS === party)[0].x - 20;
        let y = this.periodParties.filter(x => x.partyIDS === party)[0].y - 20;
        return `top: ${y}%; left: ${x}%`;
      } else {
        return "top: -36%; left: -36%; opacity: 0; cursor: initial";
      }
    }
  },
  computed: {
    selectedPeriod: function() {
      if (this.fixedPeriod) {
        return this.fixedPeriod;
      } else {
        return this.globalPeriod;
      }
    },
    periodParties: function() {
      return this.randomizedPeriodParties[this.selectedPeriod];
    },
    randomizedPeriodParties: function() {
      let copy = JSON.parse(JSON.stringify(this.sectionData.govPeriods));
      for (let period in copy) {
        copy[period] = copy[period].map(x => {
          x.x = x.x + (Math.random() - 0.5) * 6;
          x.y = x.y + (Math.random() - 0.5) * 6;
          return x;
        });
      }
      return copy;
    }
  },
  created() {
    this.$eventBus.$on("selectedPeriod", this.setSelectedPeriod);
  },
  beforeDestroy() {
    this.$eventBus.$off("selectedPeriod");
  }
};
</script>
