<template>
  <div class="outer-container">
    <div class="circles-container-topic" :style="showAllTopics?'width: 50%':''">
      <template v-for="(alliance, index) in selectedAlliances">
        <div class="circle-container" :class="!showAllTopics?`circle-container-${index+1}`:`circle-container-modified-${index+1}`" :key="index">
          <div class="circle" :style="`width: ${circleSize(normalizePercentage(percentages, index))}%; height: ${circleSize(normalizePercentage(percentages, index))}%`">
            <div class="circle-percentage">
              <p v-if="percentage">
                <span v-if="index == 0">Har sammen vedtaget </span>
                {{alliance.percentage.toFixed(0)}} %
                <span v-if="index == 0"> af alle forslag i perioden</span>
              </p>
              <p v-else>
                <span v-if="index == 0">Har sammen vedtaget </span>
                {{alliance.count}}
                <span v-if="index == 0"> forslag i perioden</span>
              </p>
            </div>
            <div class="circle-parties">
              <template v-for="(party, index) in alliance.parties">
                <div class="party-logo" :key="index">
                  <PartyLogo :party="party" :size="'med'" :circle="true" />
                </div>
              </template>
              <!-- <p>{{alliance.parties}}</p> -->
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="circles-container-all" v-if="!onlyAverage" :style="showAllTopics?'left: 50%':''">
      <div class="all-flag" @click="showAllTopics = !showAllTopics">
        <p v-if="!showAllTopics">PÅ TVÆRS AF EMNER</p>
        <p v-else>SKJUL</p>
      </div>
      <template v-for="(alliance, index) in averageAlliances">
        <div class="circle-container" :class="`circle-container-all-modified-${index+1} circle-container-modified-${index+1}`" :key="index">
          <div class="circle" :style="`width: ${circleSize(normalizePercentage(percentages, index))}%; height: ${circleSize(normalizePercentage(percentages, index))}%`">
            <div class="circle-percentage">
              <p v-if="percentage">{{alliance.percentage.toFixed(0)}}%</p>
              <p v-else>{{alliance.count}}</p>
            </div>
            <div class="circle-parties">
              <template v-for="(party, index) in alliance.parties">
                <div class="party-logo" :key="index">
                  <PartyLogo :party="party" :size="'med'" :circle="true" />
                </div>
              </template>
              <!-- <p>{{alliance.parties}}</p> -->
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="toggles-container">
      <div class="type-toggle" :style="showTypeToggle?'left: 75%':''" :class="onlyAverage?'move-up':''">
        <div class="type-flag" @click="showTypeToggle = !showTypeToggle">
          <p>{{lawTypes==="alle"?"ALLE FORSLAG":lawTypes.toUpperCase()}}</p>
        </div>
        <div class="type-btn" :class="lawTypes === 'lovforslag'?'clicked':''" @click="lawTypes = 'lovforslag'; showTypeToggle= false">
          <p>LOVFORSLAG</p>
        </div>
        <div class="type-btn" :class="lawTypes === 'beslutningsforslag'?'clicked':''" @click="lawTypes = 'beslutningsforslag'; showTypeToggle= false">
          <p>BESLUTNINGSFORSLAG</p>
        </div>
        <div class="type-btn" :class="lawTypes === 'alle'?'clicked':''" @click="lawTypes = 'alle'; showTypeToggle= false">
          <p>ALLE FORSLAG</p>
        </div>
      </div>
      <div class="percentage-toggle" :style="showPercentageToggle?'left: 75%':''" :class="onlyAverage?'move-up':''">
        <div class="percentage-flag" @click="showPercentageToggle = !showPercentageToggle">
          <p>PROCENT / ABSOLUT</p>
        </div>

        <div class="percentage-btn" :class="percentage?'clicked':''" @click="percentage = true; showPercentageToggle= false">
          <p>VIS PROCENTANDEL</p>
        </div>
        <div class="percentage-btn" :class="!percentage?'clicked':''" @click="percentage = false; showPercentageToggle= false">
          <p>VIS ANTAL</p>
        </div>
      </div>
    </div>

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";

.outer-container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.type-toggle {
  position: absolute;
  left: 100%;
  top: calc(100% / 3 + 100% / 3 * 0.5);
  transform: translateY(-50%);
  height: 25%;
  width: 25%;
  background: $color-orange;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  transition: 0.3s;
  &.move-up {
    top: calc(100% / 3 + 100% / 3 * 0.5 - 100% / 3 / 2);
  }
  .type-btn {
    height: 25%;
    width: 85%;
    background: rgba(255, 255, 255, 0.2);
    color: white;
    // border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2%;
    box-sizing: border-box;
    cursor: pointer;
    p {
      margin: 0;
      font-family: "futura-pt-condensed";
      font-weight: 700;
      font-size: 120%;
    }
  }
  .type-flag {
    position: absolute;
    height: 100%;
    width: 22%;
    background: $color-orange;
    right: 99%;
    top: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s;
    &:hover {
      right: 100%;
    }
    p {
      font-family: "futura-pt-condensed", sans-serif;
      font-weight: 700;
      color: white;
      font-size: 140%;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: rotate(180deg);
      margin: 0 !important;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
}
.percentage-toggle {
  position: absolute;
  left: 100%;
  top: calc(100% / 3 * 2 + 100% / 3 * 0.5);
  transform: translateY(-50%);
  height: 25%;
  width: 25%;
  background: $color-orange;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  transition: 0.3s;
  &.move-up {
    top: calc(100% / 3 * 2 + 100% / 3 * 0.5 - 100% / 3 / 2);
  }
  .percentage-btn {
    height: 25%;
    width: 85%;
    background: rgba(255, 255, 255, 0.2);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 2%;
    box-sizing: border-box;
    cursor: pointer;
    p {
      margin: 0;
      font-family: "futura-pt-condensed";
      font-weight: 700;
      font-size: 120%;
    }
  }
  .percentage-flag {
    position: absolute;
    height: 100%;
    width: 22%;
    background: $color-orange;
    right: 99%;
    top: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s;
    &:hover {
      right: 100%;
    }
    p {
      font-family: "futura-pt-condensed", sans-serif;
      font-weight: 700;
      color: white;
      font-size: 140%;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: rotate(180deg);
      margin: 0 !important;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
}
.clicked {
  background: $color-grey !important;
}
.circles-container-topic {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: white;
  transition: 0.3s;
  .circle {
    background: $color-grey;
  }
  h4 {
    font-size: 100%;
    font-family: "futura-pt-condensed";
    font-weight: 700;
    text-transform: uppercase;
  }
}
.circles-container-all {
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  left: 100%;
  background: $color-grey;
  transition: 0.3s;
  .circle {
    background: white;
  }
  .all-flag {
    position: absolute;
    top: calc(100% / 3 * 0.5);
    transform: translateY(-50%);
    right: 99%;
    width: 11%;
    height: 25%;
    background: $color-grey;
    // border-radius: 5px 0 0 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s;
    &:hover {
      right: 100%;
    }
    p {
      font-family: "futura-pt-condensed", sans-serif;
      font-weight: 700;
      color: white;
      font-size: 140%;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: rotate(180deg);
      margin: 0 !important;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
}
.periods-container {
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.circle-container {
  position: absolute;
  width: 50%;
  height: 50%;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-family: "futura-pt-condensed";
    font-weight: 600;
    font-size: 150%;
  }
  &.circle-container-1 {
    top: 4%;
    left: 4%;
    .circle-percentage {
      top: 20%;
      right: -80%;
      width: 70%;
      text-align: left;
    }
  }
  &.circle-container-modified-1 {
    width: calc(100% * 0.65);
    height: calc(50% * 0.65);
    top: 5%;
    left: 5%;
    .circle-percentage {
      top: 20%;
      right: -50%;
      width: 45%;
      text-align: left;
      p {
        font-size: 80%;
      }
    }
  }
  &.circle-container-all-modified-1 {
    .circle-percentage {
      bottom: -20% !important;
      p {
        font-size: 80%;
      }
    }
  }
  &.circle-container-2 {
    bottom: 5%;
    left: 14%;
    .circle-percentage {
      left: -11%;
      bottom: -11%;
    }
  }
  &.circle-container-modified-2 {
    width: calc(100% * 0.65);
    height: calc(50% * 0.65);
    bottom: 7%;
    left: 4%;
    .circle-percentage {
      left: -9%;
      bottom: -9%;
      p {
        font-size: 80%;
      }
    }
  }
  &.circle-container-3 {
    bottom: 50%;
    right: 4%;
    transform: translateY(50%);
    .circle-percentage {
      bottom: -8%;
      right: -8%;
    }
  }
  &.circle-container-modified-3 {
    width: calc(100% * 0.65);
    height: calc(50% * 0.65);
    bottom: 50%;
    transform: translateY(50%);
    right: 0%;
    .circle-percentage {
      bottom: -8%;
      right: -8%;
      p {
        font-size: 80%;
      }
    }
  }
  .circle {
    position: relative;
    transition: 0.3s;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .circle-percentage {
      position: absolute;
      font-weight: 800;
      font-family: "futura-pt", sans-serif;
    }
    .circle-parties {
      // background: rgba(0, 0, 0, 0.1);
      width: 60%;
      height: 60%;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: center;
      .party-logo {
        width: 25%;
        height: 25%;
        margin: 4%;
      }
    }
  }
}
@media (max-width: $bp-narrow) {
  .outer-container {
    font-size: 1vw !important;
  }
  .circle-percentage {
    p {
      font-size: 180%;
    }
  }
}
</style>

<script>
import PeriodSelector from "@/components/PeriodSelector";
import PartyLogo from "@/components/PartyLogo";
export default {
  props: {
    sectionData: {},
    averageData: {},
    onlyAverage: {
      default: false
    }
  },
  components: {
    PeriodSelector,
    PartyLogo
  },
  data() {
    return {
      page1: true,
      selectedPeriod: "allPeriods",
      percentage: true,
      lawTypes: "lovforslag",
      showAllTopics: false,
      showTypeToggle: false,
      showPercentageToggle: false
    };
  },
  methods: {
    partyLogo: function(party) {
      return require(`@/assets/parties/logos/new/${party}_med.png`);
    },
    normalizePercentage: function(percentages, index) {
      let min = 6;
      let max = 85;
      let value = percentages[index];
      return ((value - min) * 100) / (max - min) / 100;
    },
    circleSize: function(weight) {
      // console.log(weight);
      return 50 + weight * 50;
    },
    setSelectedPeriod: function(event) {
      this.selectedPeriod = event;
      this.selectedPage = 1;
    }
  },
  computed: {
    selectedAlliances: function() {
      return this.sectionData.govPeriods[this.selectedPeriod][
        this.lawTypes
      ].alliances.sort((a, b) => b.percentage - a.percentage);
    },
    averageAlliances: function() {
      return this.averageData.govPeriods[this.selectedPeriod][
        this.lawTypes
      ].alliances.sort((a, b) => b.percentage - a.percentage);
    },
    percentages: function() {
      return this.selectedAlliances.map(x => x.percentage);
    }
  },
  created() {
    this.$eventBus.$on("selectedPeriod", this.setSelectedPeriod);
  },
  beforeDestroy() {
    this.$eventBus.$off("selectedPeriod");
  }
};
</script>
