<template>
  <div class="outer-container">
    <transition name="fade">
      <div class="back-arrow" @click="initialScreen = true" v-if="!initialScreen">
        <img src="@/assets/icons/back-black.svg" alt="">
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <div class="initial-screen" key="1" v-if="initialScreen">
        <div>
          <h1 class="title">VÆLG ET PARTI</h1>
        </div>
        <div class="party-logo-container">
          <template v-for="(partyName, party, index) in parties">
            <div class="party-logo-start" :class="periodParties.indexOf(party)!==-1?'':'disabled-party'" :key="index" @click="selectParty(party)">
              <PartyLogo :party="party" :size="'med'" />
            </div>
          </template>
        </div>
      </div>
      <div class="circle-container" key="2" v-else>
        <transition name="fade">
          <div class="outer-circle-container" v-if="!changingParty">
            <template v-for="(party, index) in otherParties">
              <li v-if="periodParties.indexOf(party) !== -1" :key="party" class="party-logo" :class="`party-${index}`" :style="partyLogoStyle(party, index)" @click="isMobile()?setActiveParty(index):changeParty(party)">
                <PartyLogo :party="party" :size="'med'" :tooltip="false" :circle="true" />
                <div class="party-percentage"><b>{{printPartyName(party)}}</b> stemte sammen med <b>{{printPartyName("null")}}</b> i {{partyVotesData.filter(x => x.partyID === party)[0].percentage.toFixed(0)}}% af tilfældene.</div>
              </li>
              <li v-else :key="party" class="party-logo" :class="`party-${index}`" :style="partyLogoStyle(party, index)" @click="isMobile()?setActiveParty(index):''">
                <PartyLogo :party="party" :size="'med'" :tooltip="false" :circle="true" style="opacity: 0.2" />
                <div class="party-percentage"><b>{{printPartyName(party)}}</b> kom i Folketinget i 2015.</div>
              </li>
            </template>
          </div>
        </transition>
        <div class="inner-circle">
          <div class="circle-text">
            <transition name="fade">
              <img v-if="!changingParty" :src="partyLogo(selectedParty)" alt="..." class="selectedPartyLogo" />
              <!-- <h2>{{parties[selectedParty].name.toUpperCase()}}:</h2> -->
            </transition>
          </div>
        </div>

        <template v-for="(i, index) in 5">
          <div class="ring-circle" :class="`ring-circle-${i}`" :key="index">
          </div>
        </template>

        <div class="scala-container" @click="isMobile()?removeActive():''">
          <p class="legend legend-start">0%</p>
          <p class="legend legend-end">100%</p>
          <div class="step-1"></div>
          <div class="step-2"></div>
          <div class="step-3"></div>
          <div class="step-4"></div>
          <div class="step-5"></div>
        </div>
      </div>
    </transition>

  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/settings.scss";
@import "./circle.scss";

.initial-screen {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  .party-logo-container {
    width: 80%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .party-logo-start {
      cursor: pointer;
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.4s;
      img {
        width: 80%;
      }
      &:hover {
        width: 15%;
      }
      &.disabled-party {
        cursor: initial;
        opacity: 0.3;
        &:hover {
          width: 10%;
        }
      }
    }
  }
}
.circle-container {
  // overflow: hidden;
  // border: 1px dashed grey;
  position: relative;
  height: 82%;
  width: 82%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.periods-container {
  margin-top: 10%;
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.outer-circle-container {
  @include on-circle($item-count: 8, $circle-size: 100%, $item-size: 10%);
  margin: 0;
  position: relative;
  z-index: 50;
  img {
    transition: 0.15s;
  }
  .party-percentage {
    position: absolute;
    background: white;
    top: 105%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 1px 1px 1px grey;
    padding: 5px;
    visibility: hidden;
    width: 180px;
    font-size: 80%;
    pointer-events: none;
    z-index: 99999;
  }
  li:hover {
    z-index: 999;
    .party-percentage {
      visibility: visible;
    }
  }
  .clicked {
    width: 75%;
    height: 75%;
  }
}
.outer-container {
  background: white;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .back-arrow {
    position: absolute;
    cursor: pointer;
    width: 5%;
    top: 2%;
    left: 2%;
    img {
      width: 100%;
    }
  }
}
.inner-circle {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  padding-top: 20%;
  background: $color-darkgrey;
  border-radius: 50%;
  // visibility: hidden;
}
.ring-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.ring-circle-1 {
  z-index: 9;
  width: 36%;
  height: 36%;
  background: rgba(0, 0, 0, 0.09);
}
.ring-circle-2 {
  z-index: 8;
  width: 52%;
  height: 52%;
  background: rgba(0, 0, 0, 0.08);
}
.ring-circle-3 {
  z-index: 7;
  width: 68%;
  height: 68%;
  background: rgba(0, 0, 0, 0.06);
}
.ring-circle-4 {
  z-index: 6;
  width: 84%;
  height: 84%;
  background: rgba(0, 0, 0, 0.04);
}
.ring-circle-5 {
  z-index: 5;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.02);
}
.circle-text {
  width: 75%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.party-logo {
  position: absolute;
  z-index: 10;
  opacity: 1;
  transition: transform 1s ease-in-out;
  cursor: pointer;
  // img {
  //   position: relative;
  //   z-index: 11 !important;
  //   border-radius: 100%;
  //   background-color: #fff;
  //   padding: 3px;
  //   box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  //   width: 80%;
  //   height: auto;
  // }
}
.selectedPartyLogo {
  // width: 60%;
  // height: 60%;

  border-radius: 100%;
  background-color: #fff;
  padding: 3px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  height: auto;
  z-index: 999 !important;
}

.scala-container {
  position: absolute;
  right: 0%;
  bottom: -5%;
  width: 30%;
  height: 5%;
  display: flex;
  .legend {
    position: absolute;
    top: 50%;
    margin: 0;
    font-family: "futura-pt";
    font-weight: 700;
  }
  .legend-start {
    left: 0;
    transform: translate(-115%, -50%);
  }
  .legend-end {
    right: 0;
    transform: translate(115%, -50%);
  }
  [class^="step-"] {
    width: 20%;
    height: 100%;
  }
  .step-1 {
    background: rgba(0, 0, 0, 0.02);
  }
  .step-2 {
    background: rgba(0, 0, 0, 0.04);
  }
  .step-3 {
    background: rgba(0, 0, 0, 0.06);
  }
  .step-4 {
    background: rgba(0, 0, 0, 0.08);
  }
  .step-5 {
    background: rgba(0, 0, 0, 0.09);
  }
}

@media (max-width: $bp-mobile) {
  .outer-circle-container {
    .party-percentage {
      top: -140%;
      left: 50%;
      transform: translateX(-50%);
      width: 150px;
      font-size: 100%;
    }
    li:hover {
      .party-percentage {
        visibility: hidden;
      }
    }
    li:active,
    li.active {
      z-index: 999;
      .party-percentage {
        visibility: visible;
      }
    }
  }
}
</style>

<script>
import parties from "@/assets/parties/parties.json";
import PartyLogo from "@/components/PartyLogo";
export default {
  props: ["sectionData"],
  components: {
    PartyLogo
  },
  data() {
    return {
      globalPeriod: "allPeriods",
      parties,
      initialScreen: true,
      selectedParty: "S",
      changingParty: false,
      prevPartyIndex: 99
    };
  },
  methods: {
    changeParty: function(party) {
      this.changingParty = true;
      setTimeout(() => {
        this.selectedParty = party;
      }, 400);
      setTimeout(() => {
        this.changingParty = false;
      }, 400);
    },
    selectParty: function(party) {
      this.selectedParty = party;
      this.initialScreen = false;
    },
    printPartyName: function(party) {
      if (party === "null") {
        party = this.selectedParty;
      }
      return this.parties[party].name;
    },
    setSelectedPeriod: function(event) {
      this.globalPeriod = event;
    },
    partyLogo: function(party) {
      return require(`@/assets/parties/logos/new/${party}_med.png`);
    },
    partyLogoStyle: function(party, index) {
      // console.log(party, index);
      let hidden = this.periodParties.indexOf(party) === -1;
      // console.log(party, hidden);
      let style = "";
      if (!hidden) {
        let percentage = this.partyVotesData
          .filter(x => x.partyID === party)[0]
          .percentage.toFixed(0);
        let value = (100 - percentage) * 4 + 90;
        style =
          "transform: rotate(" +
          (0 + index * (360 / 8)) +
          "deg) translate(" +
          value +
          "%) rotate(-" +
          (0 + index * (360 / 8)) +
          "deg);";
      } else {
        let percentage = 0;
        let value = (100 - percentage) * 4 + 90;
        style =
          "transform: rotate(" +
          (0 + index * (360 / 8)) +
          "deg) translate(" +
          value +
          "%) rotate(-" +
          (0 + index * (360 / 8)) +
          "deg);";
      }
      return style;
    },
    isMobile() {
      if (window.innerWidth <= 800) {
        return true;
      } else {
        return false;
      }
    },
    setActiveParty(index) {
      if (index === this.prevPartyIndex) {
        this.removeActive();
      } else {
        let el = document.querySelector(".party-logo.party-" + index);
        let siblings = n => [...n.parentElement.children].filter(c => c != n);

        let others = siblings(el);
        others.forEach(e => e.classList.remove("active"));

        el.classList.add("active");
        this.prevPartyIndex = index;
      }
    },
    removeActive() {
      let all = document.querySelectorAll(".party-logo");
      all.forEach(e => e.classList.remove("active"));
    }
  },
  computed: {
    selectedPeriod: function() {
      if (this.fixedPeriod) {
        return this.fixedPeriod;
      } else {
        return this.globalPeriod;
      }
    },
    periodParties: function() {
      return Object.keys(this.sectionData.govPeriods[this.selectedPeriod]);
    },
    otherParties: function() {
      let response = JSON.parse(JSON.stringify(Object.keys(this.parties)));
      let index = response.indexOf(this.selectedParty);
      response.splice(index, 1);
      return response;
    },
    partyVotesData: function() {
      if (this.sectionData && this.selectedPeriod) {
        return this.sectionData.govPeriods[this.selectedPeriod][
          this.selectedParty
        ];
      } else {
        return [];
      }
    }
  },
  watch: {
    selectedPeriod: function() {
      if (
        this.selectedPeriod === "period1" ||
        this.selectedPeriod === "period2"
      ) {
        if (this.selectedParty === "ALT") {
          this.initialScreen = true;
        }
      }
    }
  },
  created() {
    this.$eventBus.$on("selectedPeriod", this.setSelectedPeriod);
  },
  beforeDestroy() {
    this.$eventBus.$off("selectedPeriod");
  }
};
</script>
